import Vue from 'vue'
import App from './App.vue'
import router from "./router"
import store from "./store/store"
import ElementUI from 'element-ui';
import axios from "axios"
import echarts from 'echarts'
//需要挂载到Vue原型上
Vue.prototype.$echarts = echarts
import '@/style/font.css'  //字体
import api from "@/utils/request";
require("./mock")

// axios.defaults.baseURL = "http://localhost:3001/";
Vue.prototype.$api = api
// import { 
//   Button,
//   Select,
//   Container,
//   Header,
//   Aside,
//   Main,
//   Footer,
//   } from 'element-ui';
import "element-ui/lib/theme-chalk/index.css"
import "./style/common.css"
Vue.prototype.$bus = new Vue()

// Vue.use(Button)
// Vue.use(Select)
// Vue.use(Container)
// Vue.use(Header)
// Vue.use(Aside)
// Vue.use(Main)
// Vue.use(Footer)
Vue.use(ElementUI);


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
