export default {
    path: '/',
    // name: 'Home',
    component: () => import(/* webpackChunkName: "Home" */ '../../views/home/Home.vue'),
    children: [
        {
            //商品管理  // 默认子路由 父路由不能有name属性
            path: "/",
            component: () => import(/* webpackChunkName: "Home" */ '../../views/index/Index.vue'),
        },
        {
            //打印发货
            path: "/order/transaction",
            component: () => import(/* webpackChunkName: "Home" */ '../../views/order/Transaction.vue'),
        },
        {
            //自由打印
            path: "/order/freePrint",
            component: () => import(/* webpackChunkName: "Home" */ '../../views/order/FreePrint.vue'),
        },
        {
            //分仓
            path: "/order/warehouse",
            component: () => import(/* webpackChunkName: "Home" */ '../../views/order/Warehouse.vue'),
        },
        {
            //审单
            path: "/order/check",
            component: () => import(/* webpackChunkName: "Home" */ '../../views/order/Check.vue'),
        },
        {
            //面单查询
            path: "/waybill/list",
            component: () => import(/* webpackChunkName: "Home" */ '../../views/waybill/List.vue'),
        },
        {
            //库存看板
            path: "/stock/dashboard",
            component: () => import(/* webpackChunkName: "Home" */ '../../views/stock/Dashboard.vue'),
        },
        {
            //库存预警
            path: "/stock/warning",
            component: () => import(/* webpackChunkName: "Home" */ '../../views/stock/Warning.vue'),
        },
        {
            //期初库存
            path: "/stock/init",
            component: () => import(/* webpackChunkName: "Home" */ '../../views/stock/Init.vue'),
        },
        {
            //库存同步
            path: "/stock/synchronous",
            component: () => import(/* webpackChunkName: "Home" */ '../../views/stock/Synchronous.vue'),
        },
        {
            //售后工作台
            path: "/aftersales/workbench",
            component: () => import(/* webpackChunkName: "Home" */ '../../views/aftersales/Workbench.vue'),
        },
        {
            //扫退货快递单
            path: "/aftersales/scan",
            component: () => import(/* webpackChunkName: "Home" */ '../../views/aftersales/Scan.vue'),
        },
        {
            //退货补寄
            path: "/aftersales/resend",
            component: () => import(/* webpackChunkName: "Home" */ '../../views/aftersales/Resend.vue'),
        },

        {
            //数据统计
            path: "/statistics",
            component: () => import(/* webpackChunkName: "Statistics" */ '../../views/statistics/Index.vue'),
        },
        {
            path: "/goods/class",
            component: () => import(/* webpackChunkName: "Classs" */ '../../views/basics/Classs.vue'),
        },
        {
            //订单状态
            path: "/order/status",
            component: () => import(/* webpackChunkName: "Statuss" */ '../../views/order/Statuss.vue'),

        },
        {
            //订单状态
            path: "/finance",
            component: () => import(/* webpackChunkName: "Statuss" */ '../../views/finance/Index.vue'),

        },


        {
            //数据统计
            path: "/order/statistics",
            component: () => import(/* webpackChunkName: "Statistics" */ '../../views/order/Statistics.vue'),
        },
        {
            //用户管理
            path: "/system/user",
            component: () => import(/* webpackChunkName: "User" */ '../../views/system/User.vue'),
        },
        {
            //支付方式
            path: "/pay/fun",
            component: () => import(/* webpackChunkName: "Fun" */ '../../views/pay/Fun.vue'),
        },
        {
            //库存
            path: "/warehouse/stock",
            component: () => import(/* webpackChunkName: "Stock" */ '../../views/warehouse/Stock.vue'),
        },
        {
            //店铺
            path: "/shop",
            component: () => import(/* webpackChunkName: "Stock" */ '../../views/shop/Index.vue'),
        },
        {
            //商品管理
            path: "/goods/list",
            component: () => import(/* webpackChunkName: "Stock" */ '../../views/goods/List.vue'),
        },

        {
            //电子面单
            path: "/setting/electronicWayBill",
            component: () => import(/* webpackChunkName: "Stock" */ '../../views/setting/ElectronicWayBill.vue'),
        },
        {
            //快递模板
            path: "/setting/expressTemplate",
            component: () => import(/* webpackChunkName: "Stock" */ '../../views/setting/ExpressTemplate.vue'),
        },
        {
            //快递模板
            path: "/setting/expressTemplate/Modules",
            component: () => import(/* webpackChunkName: "Stock" */ '../../views/setting/Modules.vue'),
        },
        {
            //多店铺设置
            path: "/setting/multipleStores",
            component: () => import(/* webpackChunkName: "Stock" */ '../../views/setting/MultipleStores.vue'),
        },
        {
            //日志
            path: "/log",
            component: () => import(/* webpackChunkName: "Stock" */ '../../views/setting/Log.vue'),
        }
    ]
}