import Mock from "mockjs"
const url = {
    test: "http://mock.test/test"
};
export default [
    Mock.mock(url.test, {
        "data|10": [
            {
                "holdShareNum|1-100": 100,
                "id|+1": 1,
                "shareName|1": ["admin1", "admin2", "admin3"]
            }
        ]
    }),
    
]