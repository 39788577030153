import axios from 'axios';
import { Message } from 'element-ui';
// 创建 axios 实例
const axiosInstance = axios.create({
    // baseURL: 'https://pdd.api.erp.cash', // 替换为你的后端 API 地址
    baseURL: 'https://manage.catpay.com.cn', // 替换为你的后端 API 地址
    timeout: 10000, // 请求超时时间
    headers: {
        'Content-Type': 'application/json'
    }
});

// 请求拦截器
axiosInstance.interceptors.request.use(
    (config) => {
        // 自动附加 Token
        const token = localStorage.getItem('token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// 响应拦截器
axiosInstance.interceptors.response.use(
    (response) => {
        const { code } = response.data;
        switch (code) {
            case 401: // 未授权
                // console.warn('Unauthorized: Redirecting to login...');
                Message({
                    message: '请重新登录',
                    type: 'error'
                });
                localStorage.removeItem('token'); // 清除 Token
                window.location.href = '/login'; // 重定向到登录页
                break;

            case 403: // 禁止访问
                Message({
                    message: '请重新登录',
                    type: 'error'
                });
                localStorage.removeItem('token'); // 清除 Token
                window.location.href = '/login'; // 重定向到登录页
                // console.warn('Forbidden: You do not have access.');
                // localStorage.removeItem('token'); // 清除 Token
                // window.location.href = '/login'; // 重定向到登录页

                break;

            case 500: // 服务器错误
                // console.error('Server Error: Please try again later.');
                break;

            default:
            // console.warn(`Unexpected Error: ${code}`);
        }


        return response.data; // 直接返回响应数据
    },
    (error) => {
        console.log(error);

        // 统一错误处理
        if (error.response) {
            const { status, data } = error.response;
            switch (status) {
                case 401: // 未授权
                    Message({
                        message: 'token已过期，请重新登录',
                        type: 'error'
                    });
                    localStorage.removeItem('token'); // 清除 Token
                    window.location.href = '/login'; // 重定向到登录页
                    break;

                case 403: // 禁止访问
                    Message({
                        message: 'token已过期，请重新登录',
                        type: 'error'
                    });
                    localStorage.removeItem('token'); // 清除 Token
                    window.location.href = '/login'; // 重定向到登录页
                    break;

                case 500: // 服务器错误
                    console.error('Server Error: Please try again later.');
                    break;

                default:
            }

            // return Promise.reject(data);
            return;
        } else {
            // return Promise.reject({ message: 'Network Error' });
            return;
        }
    }
);

// 简化调用的封装方法
const request = (method, url, data = {}, config = {}) => {
    return axiosInstance({
        method,
        url,
        data,
        ...config
    });
};

// 导出封装方法
export default {
    get: (url, params = {}, config = {}) =>
        request('GET', url, {}, { ...config, params }), // GET 请求使用 params
    post: (url, data = {}, config = {}) => request('POST', url, data, config),
    put: (url, data = {}, config = {}) => request('PUT', url, data, config),
    delete: (url, data = {}, config = {}) => request('DELETE', url, data, config)
};
