import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: 'null',  //初始信息为空
    identity: localStorage.getItem("identity") || 1,//1普通版 2分销版
  },
  mutations: {
    SET_USER(state, user) {
      state.user = user;
    },
    LOGOUT(state) {
      // 清除用户信息  
      state.user = null;
      state.identity = null
    },
    SET_IDENTITY(state, identity) {
      state.identity = identity;
    },

  },
  actions: {
    setUser({ commit }, user) {
      commit('SET_USER', user);
    },
    setIdentity({ commit }, identity) {
      commit('SET_IDENTITY', identity);
    },
    logout({ commit }) {
      // 提交 mutation 来清除用户信息  
      commit('LOGOUT');

    }

  },
  modules: {
  }
})