import Vue from 'vue'
import VueRouter from 'vue-router'

//导入路由配置
import Login from "./login"
import Sign from "./sign"
import EditPwd from "./editPwd"
import Home from "./home"



// 禁用重复导航报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)

const routes = [
    Login,    //登录页
    Sign,   //注册页
    EditPwd, //修改密码页
    Home,    //主页
    {
        path: "*",
        redirect: "/login"
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router