<template>
  <div id="app">
  
    <router-view></router-view>
   
    
  </div>
</template>

<script>


export default {
  name: 'app',
  created(){
    var url = new URL(window.location.href);  
    var path = url.pathname; // 获取路径部分，例如 "/user"  
      
    console.log(path);
  },
  components: {

  }
}
</script>

<style lang="less">
#app {
  width: 100vw;
  height: 100vh;
  text-align: center;
  color: #2c3e50;
  background-color: #E9EEF3;
  // display: flex;
}

</style>
